<template>
  <header class="header">
    <div class="container">
      <button
          class="header__menu-btn"
          @click.prevent="isMenuVisible = !isMenuVisible"
          :class="{active: isMenuVisible}"
      >
        <span></span>
        <span></span>
      </button>
      <div
          :class="{active: isMenuVisible}"
          class="header__menu">
        <div class="header__logo"></div>
         <div class="header__menu-inner">
           <div class="header__menu-label">Партнеры фестиваля</div>
                   <div class="header__wrapper-icons">
<!--                     <a href="https://vk.com/punchlinefest/" target="_blank" class="header__partner-link">
                     <img src="@/assets/images/partners2023/1_vk_logo.svg" class="header__partner-img ">
                     </a>-->
                     <a href="https://standupclub.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/2_sc_t_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://gostandup.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/3_gst_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://bandband.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/band_logo_3.svg" class="header__partner-img ">
                     </a>
                     <a href="https://comedy-radio.ru/" target="_blank" class="header__partner-link ">
                       <img src="@/assets/images/partners2023/comedyradio_logo.svg" class="header__partner-img">
                     </a>
                     <!--<a href="https://urent.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/urent_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://2gis.ru/moscow" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/2gis_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://myphotopages.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/mpp_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://flowwow.com/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/14_flowers_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://xn&#45;&#45;&#45;&#45;7sbbf2bcibrdce.xn&#45;&#45;p1ai/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/vocal_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://www.mosigra.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/12_mosigra.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://www.afisha.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/afisha_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                     </a>
                     <a href="https://shambalawater.ru" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/13_shambala_logo.svg" class="header__partner-img header__partner-img&#45;&#45;extraw">
                     </a>
                     <a href="https://comedy-radio.ru" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/comedy_radio_logo.svg" class="header__partner-img">
                     </a>
                     <a href="https://oblickmirror.ru/" target="_blank" class="header__partner-link">
                       <img src="@/assets/images/partners2023/oblik_w_logo.svg" class="header__partner-img header__partner-img&#45;&#45;extraw">
                   </a>-->
                   </div>
          <div class="header__menu-label header__menu-label&#45;&#45;mt">Панчлайн лайв</div>
          <div class="header__wrapper-icons header__wrapper-icons&#45;&#45;socials">
            <a href="https://t.me/punchlinefest" target="_blank" class="header__link">
              <img src="@/assets/images/icons/telegram.svg" alt="telegram" class="header__icon header__icon&#45;&#45;telegram">
            </a>
            <a href="https://vk.com/punchlinefest" target="_blank" class="header__link">
              <img src="@/assets/images/icons/vk.svg" alt="vk" class="header__icon header__icon&#45;&#45;vk">
            </a>
            <a href="https://www.youtube.com/channel/UCyHb9xSOqeRMZkVERmwNlUw" target="_blank" class="header__link">
              <img src="@/assets/images/icons/youtube.svg" alt="youtube" class="header__icon header__icon&#45;&#45;youtube">
            </a>
            <a href="#" target="_blank" class="header__link">
              <img src="@/assets/images/icons/logonuum.svg" alt="youtube" class="header__icon header__icon&#45;&#45;nuum">
            </a>
          </div>
        </div>
      </div>
      <div class="header__inner">
        <div class="header__logo"></div>
        <div class="header__block-icons">
          <a href="https://t.me/punchlinefest" target="_blank" class="header__link">
            <img src="@/assets/images/icons/telegram.svg" alt="telegram" class="header__icon header__icon--telegram">
          </a>
          <a href="https://vk.com/punchlinefest" target="_blank" class="header__link">
            <img src="@/assets/images/icons/vk.svg" alt="vk" class="header__icon header__icon--vk">
          </a>
          <a href="https://www.youtube.com/channel/UCyHb9xSOqeRMZkVERmwNlUw" target="_blank" class="header__link">
            <img src="@/assets/images/icons/youtube.svg" alt="youtube" class="header__icon header__icon--youtube">
          </a>
          <a href="https://nuum.ru/channel/punchline" target="_blank" class="header__link">
            <img src="@/assets/images/icons/logonuum.svg" alt="nuum" class="header__icon header__icon--nuum">
          </a>
        </div>
        <div class="header__partners">
          <div class="header__text">Партнеры</div>
<!--          <a href="https://vk.com/punchlinefest/" target="_blank" class="header__partner-link">
            <img src="@/assets/images/partners2023/1_vk_logo.svg" class="header__partner-img ">
          </a>-->
          <a href="https://standupclub.ru/" target="_blank" class="header__partner-link">
            <img src="@/assets/images/partners2023/2_sc_t_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
          </a>
          <a href="https://gostandup.ru/" target="_blank" class="header__partner-link">
            <img src="@/assets/images/partners2023/3_gst_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
          </a>
          <a href="https://bandband.ru/" target="_blank" class="header__partner-link">
            <img src="@/assets/images/partners2023/band_logo_3.svg" class="header__partner-img header__partner-img&#45;&#45;medium">
          </a>
          <a href="https://comedy-radio.ru/" target="_blank" class="header__partner-link ">
            <img src="@/assets/images/partners2023/comedyradio_logo.svg" class="header__partner-img header__partner-img&#45;&#45;medium">
          </a>
          <!--          <a href="https://urent.ru/" target="_blank" class="header__partner-link">
                      <img src="@/assets/images/partners2023/urent_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://2gis.ru/moscow" target="_blank" class="header__partner-link">
                      <img src="@/assets/images/partners2023/2gis_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://myphotopages.ru/" target="_blank" class="header__partner-link">
                      <img src="@/assets/images/partners2023/mpp_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://flowwow.com/" target="_blank" class="header__partner-link">
                      <img src="@/assets/images/partners2023/14_flowers_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://xn&#45;&#45;&#45;&#45;7sbbf2bcibrdce.xn&#45;&#45;p1ai/" target="_blank" class="header__partner-link">
                      <img src="@/assets/images/partners2023/vocal_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://www.mosigra.ru/" target="_blank" class="header__partner-link">
                        <img src="@/assets/images/partners2023/12_mosigra.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://www.afisha.ru/" target="_blank" class="header__partner-link">
                        <img src="@/assets/images/partners2023/afisha_logo.svg" class="header__partner-img header__partner-img&#45;&#45;small">
                    </a>
                    <a href="https://shambalawater.ru" target="_blank" class="header__partner-link">
                        <img src="@/assets/images/partners2023/13_shambala_logo.svg" class="header__partner-img header__partner-img&#45;&#45;extraw">
                    </a>
                    <a href="https://comedy-radio.ru" target="_blank" class="header__partner-link">
                        <img src="@/assets/images/partners2023/comedy_radio_logo.svg" class="header__partner-img">
                    </a>
                    <a href="https://oblickmirror.ru/" target="_blank" class="header__partner-link">
                        <img src="@/assets/images/partners2023/oblik_w_logo.svg" class="header__partner-img header__partner-img&#45;&#45;extraw">
                    </a>-->
          
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      isMenuVisible: false,
    }
  },
  watch: {
    isMenuVisible() {
      document.querySelector('body').style.overflow = this.isMenuVisible ? 'hidden' : null;
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 100vh;
  width: 93px;
  background: $purple;
  position: fixed;
  z-index: 26;
  left: 0;
  top: 0;
  @media (max-width: 1800px) {
    height: 66px;
    width: 100%;
    position: relative;
  }
  @media (max-width: 992px) {
    height: auto;
    padding-top: 25px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 576px) {
    padding-top: 23px;
    z-index: 42;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (max-width: 1800px) {
      flex-direction: row;
      height: 100%;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__logo {
    flex-shrink: 0;
    padding: 30px 0 26px 0;
    width: 30px;
    height: 24px;
    background: url("~@/assets/images/logo.svg") center /contain no-repeat;
    order: -1;
    @media (max-width: 1800px) {
      padding: 0;
      margin-right: 50px;
    }
    @media (max-width: 1200px) {
      margin-right: 30px;
    }
    @media (max-width: 992px) {
      position: absolute;
      top: 21px;
      left: 32px;
    }
    @media (max-width: 576px) {
      width: 25px;
      height: 20px;
      left: 20px;
    }
  }

  &__block-icons {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 15px 0;
    @media (max-width: 2000px) {
      padding: 20px 0 15px 0;
    }
    @media (max-width: 1800px) {
      flex-direction: row;
      justify-content: flex-end;
      padding: 0;
      border: none;
      order: 3;
      margin-left: auto;
    }
  }

  &__link {
    margin-bottom: 2.2vw;
    display: block;
    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 1800px) {
      margin: 0 24px 0 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__icon {
    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.6;
    }

    &--vk {
      width: 22px;
      height: 13px;
    }

    &--youtube {
      width: 21px;
      height: 15px;
    }

    &--nuum {
      width: 70px;
      height: 60px;
      margin-top: -25px;
      margin-bottom: -20px;
      @media (max-width: 1800px) {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: -10px;
      }
      @media (max-width: 560px) {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: -25px;
      }
    }


    &--telegram {
      width: 20px;
      height: 17px;
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0 0;
    @media (max-width: 2000px) {
      padding: 15px 0 0 0;
    }
    @media (max-width: 1800px) {
      flex-direction: row;
      padding: 0;
      order: 2;
      height: 100%;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  &__partner-link {
    display: block;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 1800px) {
      margin: 0 10px 0 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 992px) {
      min-height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(5) {
        margin-right: 0;
      }

      margin: 0 15px 26px 15px;
      &:last-child {
        margin-bottom: auto;
      }
    }

    @media (max-width: 576px) {
      margin: 0 10px 12px 10px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(5) {
        margin-right: 24px;
      }
    }
    @media (max-width: 330px) {
      justify-content: center;
      width: 57px;
      flex-shrink: 0;
      margin: 0 5px 5px 5px ;
      &:nth-child(5) {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 830px)
    and (min-width: 576px)
    and (orientation:landscape) {
      margin: 0 10px 25px 10px;
      &:nth-child(4n) {
        margin-right: 20px;
      }
      &:nth-child(5) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 576px)
    and (min-width: 320px)
    and (orientation:landscape) {
      margin: 0 10px 10px 10px;
      &:nth-child(4n) {
        margin-right: 10px;
      }
      &:nth-child(5) {
        margin-right: 0;
      }
    }
  }

  &__partner-img {
    object-fit: contain;
    width: 2.1vw;
    min-width: 40px;
    max-width: 45px;
    height: auto;
    &:hover {
      opacity: 0.7;
    }
    &-m{
      margin-top:10px;
      @media (max-width: 1800px) {
        margin-top: 0;
      }
    }
    &:active {
      opacity: 0.6;
    }
    &--bell {
      transform: scale(1.5) translateX(1px);
      //@media (max-width: 15)
      @media (max-width: 1800px) {
        transform: scale(1.3);
      }
      @media (max-width: 1350px) {
        transform: scale(1.4);
      }
    }

    &--big {
      width: 1.6vw;
      min-width: 45px;
      @media (max-width: 1800px) {
        min-width: 40px;
      }
      @media (max-width: 1350px) {
        min-width: 40px;
      }
    }
    @media (max-width: 1800px) {
      width: 40px;
      max-width: initial;
      min-width: auto;
    }
    @media (max-width: 1350px) {
      width: 4vw;
      min-width: 45px;
      max-width: 35px;
    }
    @media (max-width: 1200px) {
      min-width: 40px;
    }
    @media (max-width: 992px) {
      width: 50px;
      max-width: initial;
    }
    @media (max-width: 330px) {
      width: 45px;
    }
    &--medium {
      max-width: 70px;
      width: 7vw;

      @media (max-width: 1800px) {
        // width: 7vw;
        max-width: 70px;
      }
      @media (max-width: 1200px) {
        min-width: 55px;
      }
      @media (max-width: 992px) {
        min-width: 70px;
      }
      @media (max-width: 576px) {
        min-width: 60px;
      }
    }
    &--large {
      max-width: 80px;

      @media (max-width: 1800px) {
        width: 4vw;
        max-width: 70px;
      }
      @media (max-width: 1200px) {
        min-width: 75px;
      }
      @media (max-width: 992px) {
        min-width: 70px;
      }
      @media (max-width: 576px) {
        min-width: 70px;
      }
    }

    &--small {
      max-width: 40px;

      @media (max-width: 1800px) {
        width: 4vw;
        max-width: 30px;
      }
      @media (max-width: 1350px) {
      width: 4vw;
      min-width: 25px;
      // max-width: 35px;
    }
      @media (max-width: 1200px) {
        width: 2vw;
        min-width: 20px;
      }
      @media (max-width: 992px) {
        min-width: 30px;
      }
      @media (max-width: 576px) {
        min-width: 30px;
      }
    }

    &--extraw {
      max-width: 70px;
      width: 7vw;
      margin: 12px 0;

      @media (max-width: 1800px) {
        // width: 7vw;
        max-width: 140px;
        
      }
      @media (max-width: 1200px) {
        min-width: 55px;
      }
      @media (max-width: 992px) {
        min-width: 140px;
      }
      @media (max-width: 576px) {
        min-width: 100px;
      }
    }

    &--allstandup {
      max-width: 60px;
      @media (max-width: 330px) {
        min-width: initial;
        width: 50px;
      }
    }
  }

  &__text {
    display: none;
    margin: 0 42px;
    font-size: 12px;
    color: #fff;
    @media (max-width: 1800px) {
      display: block;
    }
    @media (max-width: 1200px) {
      margin: 0 25px;
    }
  }

  &__menu-btn {
    margin-left: auto;
    padding: 10px;
    position: relative;
    z-index: 20;
    display: none;
    transform: translateY(-6px);
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    span {
      display: block;
      width: 24px;
      height: 2px;
      background: #fff;
      margin-bottom: 6px;
      transition: all .3s ease-out 0s;
      will-change: filter;
      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 576px) {
        width: 17px;
        margin-bottom: 4px;
      }
    }

    @media (max-width: 992px) {
      display: block;
    }
    @media (max-width: 576px) {
      padding-right: 0;
    }

    &.active {
      span {
        &:first-child {
          transform: rotate(45deg) translate(2px, -1px);
          @media (max-width: 576px) {
            transform: rotate(
                    40deg
            ) translate(0px, -1px)
          }
        }

        &:last-child {
          transform: rotate(-45deg) translate(6px, -4px);
          @media (max-width: 576px) {
            transform: rotate(
                    -40deg
            ) translate(5px, -5px)
          }
        }
      }
    }
  }

  &__menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $purple;
    z-index: 18;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__wrapper-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 445px;
    margin: 0 auto -26px auto;
    @media (max-width: 576px) {
      max-width: 335px;
    }
    @media (max-width: 330px) {
      max-width: 270px;
    }
    @media screen and (max-width: 830px)
    and (min-width: 320px)
    and (orientation:landscape) {
      max-width: 380px;
    }
  }

  &__menu-label {
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 53px;

    @media (max-width: 576px) {
      margin-bottom: 29px;
      font-size: 16px;
      line-height: 16px;
    }
    @media screen and (max-width: 830px)
    and (min-width: 320px)
    and (orientation:landscape) {
      margin-bottom: 20px;
      font-size: 22px;
    }
    @media screen and (max-width: 576px)
    and (min-width: 320px)
    and (orientation:landscape) {
      margin-bottom: 15px;
      font-size: 20px;
    }
    &--mt {
      margin-top: 90px;
      @media (max-width: 576px) {
        margin-top: 53px;
      }
      @media (max-width: 330px) {
        margin-top: 42px;
      }
      @media screen and (max-width: 830px)
      and (min-width: 576px)
      and (orientation:landscape) {
        margin: 3vw 0 2vw;
      }
      @media screen and (max-width: 576px)
      and (min-width: 320px)
      and (orientation:landscape) {
        margin-top: 6.5vw;
      }
    }
  }

  &__menu-img {
    position: absolute;
    left: 12vw;
    bottom: 0;
    width: 60vw;
    max-width: 450px;
    z-index: -1;

    @media (max-width: 576px) {
      width: 90vw;
      left: 5vw;
    }
    @media (max-width: 330px) {
      width: 65vw;
    }
    @media screen and (max-width: 830px)
    and (min-width: 320px)
    and (orientation:landscape) {
      width: 40vw;
      left: 0;
    }
  }

  &__menu-inner {
    margin-top: 137px;

    @media (max-width: 576px) {
      margin-top: 22vw;
    }
    @media screen and (max-width: 830px)
    and (min-width: 320px)
    and (orientation:landscape) {
      margin-top: 5.5vw;
    }
    @media screen and (max-width: 576px)
    and (min-width: 320px)
    and (orientation:landscape) {
      margin-top: 7vw;
    }
  }
}
</style>
